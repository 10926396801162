<template>
  <div>
    sss
  </div>
</template>

<script>
import {
  BTableSimple, BThead, BTbody, BTh, BTd, BTr,
} from 'bootstrap-vue'

export default {
  name: 'GelirGider',
  components: {
    BTableSimple, BThead, BTbody, BTh, BTd, BTr,
  },
}
</script>

<style scoped>

</style>
